/*
 * a backwards compatable implementation of postMessage
 * by Josh Fraser (joshfraser.com)
 * released under the Apache 2.0 license.
 *
 * this code was adapted from Ben Alman's jQuery postMessage code found at:
 * http://benalman.com/projects/jquery-postmessage-plugin/
 *
 * other inspiration was taken from Luke Shepard's code for Facebook Connect:
 * http://github.com/facebook/connect-js/blob/master/src/core/xd.js
 *
 * the goal of this project was to make a backwards compatable version of postMessage
 * without having any dependency on jQuery or the FB Connect libraries
 *
 * my goal was to keep this as terse as possible since my own purpose was to use this
 * as part of a distributed widget where filesize could be sensative.
 *
 */

// everything is wrapped in the XD function to reduce namespace collisions
/*
 * a backwards compatable implementation of postMessage
 * by Josh Fraser (joshfraser.com)
 * released under the Apache 2.0 license.
 *
 * this code was adapted from Ben Alman's jQuery postMessage code found at:
 * http://benalman.com/projects/jquery-postmessage-plugin/
 *
 * other inspiration was taken from Luke Shepard's code for Facebook Connect:
 * http://github.com/facebook/connect-js/blob/master/src/core/xd.js
 *
 * the goal of this project was to make a backwards compatable version of postMessage
 * without having any dependency on jQuery or the FB Connect libraries
 *
 * my goal was to keep this as terse as possible since my own purpose was to use this
 * as part of a distributed widget where filesize could be sensative.
 *
 */

// everything is wrapped in the XD function to reduce namespace collisions
'use strict';
var XD = function(w){
    var interval_id,
    last_hash,
    cache_bust = 1,
    attached_callback,
    window = w;

    return {
        postMessage : function(message, target_url, target) {
            if (!target_url) {
                return;
            }
            target = target || parent;  // default to parent
            if (window['postMessage']) {
                // the browser supports window.postMessage, so call it with a targetOrigin
                // set appropriately, based on the target_url parameter.
        		target['postMessage'](message, target_url.replace( /([^:]+:\/\/[^\/]+).*/, '$1'));

            } else if (target_url) {
                // the browser does not support window.postMessage, so set the location
                // of the target to target_url#message. A bit ugly, but it works! A cache
                // bust parameter is added to ensure that repeat messages trigger the callback.
                target.location = target_url.replace(/#.*$/, '') + '#' + (+new Date) + (cache_bust++) + '&' + message;
            }
        },

        receiveMessage : function(callback, source_origin) {
            // browser supports window.postMessage
            if (window['postMessage']) {
                // bind the callback to the actual event associated with window.postMessage
                if (callback) {
                    attached_callback = function(e) {
                        if ((typeof source_origin === 'string' && source_origin.indexOf(e.origin) < 0)
                        || (Object.prototype.toString.call(source_origin) === "[object Function]" && source_origin(e.origin) === !1)) {
                            return !1;
                        }
                        callback(e);
                    };
                }
                if (window['addEventListener']) {
                    window[callback ? 'addEventListener' : 'removeEventListener']('message', attached_callback, !1);
                } else {
                    window[callback ? 'attachEvent' : 'detachEvent']('onmessage', attached_callback);
                }
            } else {
                // a polling loop is started & callback is called whenever the location.hash changes
                interval_id && clearInterval(interval_id);
                interval_id = null;

                if (callback) {
                    interval_id = setInterval(function(){
                        var hash = document.location.hash,
                        re = /^#?\d+&/;

                        if (hash !== last_hash && re.test(hash)) {
                            last_hash = hash;
                            callback({data: hash.replace(re, '')});
                        }
                    }, 100);
                }
            }
        }
    };
}(window);

/************************************************************************
	Samba player Utils
*************************************************************************/
var SambaPlayerUtils = {
	URL_BASE: function(){
		var url = this.getAllElementsWithAttribute('samba.player.api.js', 'script')[0].src;
		return url.substring(0, url.lastIndexOf('/')) + '/sambalabs/';
	},
	getAllElementsWithAttribute: function(src, tagName) {
		var matchingElements = [];
		var allElements = document.querySelectorAll(tagName);
		for (var i = 0; i < allElements.length; i++) {
			if (allElements[i].src.indexOf(src) > -1)
			{
				// Element exists with attribute. Add to array.
				matchingElements.push(allElements[i]);
			}
		}
		return matchingElements;
	},
	createAndAppendScript: function(name, params, cb) {
		var script = document.createElement("script");
		script.type = "text/javascript";
		script.src = this.URL_BASE() + name;
		for(var key in params) {
			script[key] = params[key];
		}
		if(cb) {
			var done = false;
			script.onload = script.onreadystatechange = function() {
				if ( !done && (!this.readyState || this.readyState === "loaded" || this.readyState === "complete") ) {
					done = true;
					cb();
				}
			}
		}

		var html = document.getElementsByTagName("html")[0];
		html.appendChild(script);
	},
	toQueryString: function(obj) {
		var str = [];

		for (var p in obj) {
			if (obj.hasOwnProperty(p) === false)
				continue;

			var param = obj[p];

			if (p === 'ad_program') {
				// removes brackets if there's any
				if (/^(\[|%5B)\w{3,5}/.test(param))
					param = param.replace(/^(\[|%5B)|(\]|%5D)$/g, '');

				// non-encoded URL with no brackets will add brackets,
				// encoded URL will remain w/o brackets
				if (/^\w{3,5}\:\/\//.test(param))
					param = '[' + encodeURIComponent(param) + ']';
			}

			str.push(p + "=" + param);
		}

		return str.join("&");
	},
	getQueryParams: function() {
		var result = {};
		var url = this.getAllElementsWithAttribute('samba.player.api.js', 'script')[0].src;

		if (url)
		{
			var params = url.slice(url.indexOf("?") + 1).split("&");
			for (var i = 0; i < params.length; i++)
			{
				var tmp = params[i].split("=");
				result[tmp[0]] = unescape(tmp[1]).replace(/\[|\]/g, "");
			}
		}

		return result;
	},
	createAndAppendIframe: function(target, name, options, cb) {
		var frame;
		var iframe;
		var iframeSRC;
		var params = (options.playerParams) ? this.toQueryString(options.playerParams) : "";
		var baseSRC = this.getIframeAddress() + options.ph;
		var baseSRCQueryParams = "jsApi=true&" + params + "&parentURL=#" + document.location.href;
		var allowAttributeContent = "geolocation; microphone; camera; encrypted-media; midi;";


		/*
		*
		* AS ALTERAÇÕES ABAIXO FORAM IMPLEMENTADAS PARA RESOLVER UM PROBLEMA DOS EVENTOS DO player.play()
		* e player.pause() ESPECÍFICOS DA VERSÃO 72 DO GOOGLE CHROME RELACIONADOS AO "USER ACTIVATION V2"
		*
		* */

        try {
            var browserNavigator = (function(){
                var ua = navigator.userAgent;
                var regex = /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i;
                var browserInfoArray = ua.match(regex) || [];

                browserInfoArray = browserInfoArray[2]
					? [browserInfoArray[1], browserInfoArray[2]]
					: [navigator.appName, navigator.appVersion, '-?'];

                if(browserInfoArray.length >= 2) {
                    return {
                        name: browserInfoArray[0].toLowerCase(),
                        version: parseInt(browserInfoArray[1])
                    }
				} else {
                    return {
                        name: null,
                        version: -1
                    }
                }
            })();

            if(browserNavigator.version >= 72 && browserNavigator.name === 'chrome') {
                allowAttributeContent += " autoplay;"
            }
        } catch (e) {
            console.log('Erro ao tratar versão do browser para User Activation V2')
        }

        /* FIM DAS ALTERAÇÕES PARA A VERSÃO 72 DO CHROME */

		try {
			iframe = document.createElement('iframe');
			iframe.setAttribute('allowfullscreen', true);
			iframe.setAttribute('webkitallowfullscreen', true);
			iframe.setAttribute('mozallowfullscreen', true);
			iframe.setAttribute('msallowfullscreen', true);
			iframe.setAttribute('allow', allowAttributeContent);
		} catch (e) {
			iframe = document.createElement("iframe");
		}

		if(options.m && !options.live) {
			iframeSRC = baseSRC + "/" + options.m + "?" + baseSRCQueryParams;
		}else if(options.live) {
			iframeSRC = baseSRC + "/live/" + options.live + "?" + baseSRCQueryParams;
		}else if(options.playlistId) {
			iframeSRC = baseSRC + "/playlist/" + options.playlistId + (options.mock ? "/mock" : "") + "?" + baseSRCQueryParams;
		}else {
			iframeSRC = baseSRC + "?frameId=" + name + "&" + baseSRCQueryParams;
		}

		iframe.src = encodeURI(iframeSRC);
		iframe.name = name;
		// IE11: iframe dimensions cannot be assigned undefined due to a bug
		// "options" var loses its reference and becomes undefined
		// seems to relate to a wrong management of the memory boundaries
		iframe.width = options.width || '100%';
		iframe.height = options.height || '100%';
		iframe.frameBorder = 0;
		iframe.scrolling = "no";

		if(options.responsive) {
			var wrap = this.addFluid(iframe);
			target.appendChild(wrap);
		}else {
			target.appendChild(iframe);
		}

		if(iframe.attachEvent) {
			iframe.attachEvent('onload', function(){
				frame = {
					src: iframe.src,
					document: frames[iframe.name]
				};

				cb(iframe, frame);
			});
		}else {
			iframe.onload = function(){
				frame = {
					src: iframe.src,
					document: frames[iframe.name]
				};

				cb(iframe, frame);
			}
		}
	},
	getIframeAddress: function() {
		var currentProtocol = this.getQueryParams().protocol || this.URL_BASE().match(/(https?:)?/)[0] || window.location.protocol;
		var iframeBaseURLMap = {
					'localhost-8080': currentProtocol + "//localhost:8080/player-api/embed/",
					'qa': currentProtocol + "//player-api.qa.sambatech.com/v3/embed/",
					'staging': currentProtocol + "//player-api.staging.sambatech.com/v3/embed/",
					'prod': currentProtocol + "//fast.player.liquidplatform.com/pApiv2/embed/"
      	};
		var iframeSrc = (iframeBaseURLMap[this.getQueryParams().iframeURL]) ? iframeBaseURLMap[this.getQueryParams().iframeURL] : iframeBaseURLMap.prod;
		return iframeSrc;
	},
	addEvent: function(evnt, elem, func) {
	   if (elem.addEventListener)  // W3C DOM
	      elem.addEventListener(evnt,func,false);
	   else if (elem.attachEvent) { // IE DOM
	      elem.attachEvent("on"+evnt, func);
	   }
	   else { // No much to do
	      elem[evnt] = func;
	   }
	},
	updateQueryStringParameter: function(uri, key, value) {
		var url = uri.split('#');
	  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
	  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
	  if (uri.match(re)) {
	    return uri.replace(re, '$1' + key + "=" + value + '$2') + '#' + url[1];
	  }
	  else {
	    return url[0] + separator + key + "=" + value + '#' + url[1];
	  }
	},
	/** Herança **/
	inherit: function(SubClass, SuperClass) {
		var T = function() {};
		T.prototype = SuperClass.prototype;
		SubClass.prototype = new T();
		SubClass.prototype.constructor = SubClass;
	},
	/**reponsive layout**/
	addFluid: function(elem) {
	  var wrap = document.createElement('div');
	  elem.className += (elem.className ? ' ' : '') + 'fluidsambaplayer-item';
	  wrap.className += 'fluidsambaplayer';
	  wrap.style.paddingTop = getRatio(elem.height, elem.width);
	  wrap.appendChild(elem);

	  addStyle();

	  return wrap;

	  function addStyle() {
		var css = [
			'.fluidsambaplayer {',
			  'width: 100%; max-width: 100%; position: relative;',
			'}',
			'.fluidsambaplayer-item {',
			  'position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;',
			'}'
		].join('');
		var head = document.head || document.getElementsByTagName('head')[0];
		var style = document.createElement('style');
		style.type = 'text/css';
		if (style.styleSheet) {
			style.styleSheet.cssText = css;
		} else {
			style.appendChild(document.createTextNode(css));
		}
		head.appendChild(style);
	  }

      function getRatio (height, width) {
	    return ((parseInt(height, 10) / parseInt(width, 10)) * 100) + '%';
	  }
	},
};

/** EVENT DISPATCHER **/

function EventDispatcher() {
	this.dict = {};
}

EventDispatcher.prototype.dict = null;

EventDispatcher.prototype.addEventListener = function(type, handler) {
	if (this.dict[type] == null)
		this.dict[type] = [];

	this.dict[type].push(handler);
};
EventDispatcher.prototype.removeEventListener = function(type, handler) {
	var handlers = this.dict[type];
	var i = -1;

	if (handlers == null)
		return;

	for (i = 0; i < handlers.length; ++i)
		if (handler === handlers[i])
			break;

	if (i > -1 && i < handlers.length)
		handlers.splice(i, 1);
};
EventDispatcher.prototype.dispatchEvent = function(e) {
	var handlers = this.dict[e.type];
	var evtReturn, evtReturnTmp;

	if (handlers == null)
		return;

	var handlersClone = handlers.concat();

	for (var i = 0; i < handlersClone.length; ++i) {
		evtReturnTmp = handlersClone[i].call(this, e);

		if (evtReturn !== false)
			evtReturn = evtReturnTmp;
	}

	return evtReturn;
};

/************************************************************************
	Objeto de menssagens
*************************************************************************/

var SambaPlayerApiMessage = function(player) {
	this.embedURL = SambaPlayerUtils.getIframeAddress();
  	this.players = {};
  	this.player = player;
	this.onLoadMedia = {};
  	this.init();
  	this.methodList = [];
};

SambaPlayerApiMessage.prototype.init = function() {
	var self = this;
	self.returnCallback = function(){};

	self.hasLoaded = false;

	var a = document.createElement("a");
	a.href = self.embedURL;
	var messageDomain = a.protocol + "//" + a.host;

	XD.receiveMessage(function(message){
		try{
			var data = eval('(' + message.data + ')');
			if(data.event == "getStatus") {
				self.returnCallback(data);
			}else {
				self.events(data);
			}
		}catch(e) {
		}
	}, messageDomain);
};

SambaPlayerApiMessage.prototype.sendMessage = function(msg, frame) {
	XD.postMessage(msg, frame.src, frame.document);
};

SambaPlayerApiMessage.prototype.events = function(player, iframe) {
	try {
		// Validations created to support prism
		// Prism when created sends 'player:prism' event
		if (player.data && player.data.type === 'player:prism') {
			this.isPrism = true;
		}
		if (this.isPrism) { // If is prism, translate the event to a known pattern
			if (player.method) {
				sambaPlayerApiMessage.callMethod(player);
			} else {
        		player = sambaPlayerApiMessage.translatePrismEvents(player);
			}
		}

		//Eventos específicos
		if(typeof this.players[player.id][player.event] == "function") {
			this.players[player.id][player.event](player);
		} else if(typeof this.players[player.id][player.event] == "string") {
			window[this.players[player.id][player.event]](player);
		}

		//Eventos globais
		if(this.players[player.id]["*"]) {
			if(typeof this.players[player.id]["*"] == "function") {
				this.players[player.id]["*"](player);
			}else if(typeof this.players[player.id]["*"] == "string") {
				window[this.players[player.id]["*"]](player);
			}
		}
	} catch (e) {
		// TODO: handle exception
	}

};

SambaPlayerApiMessage.prototype.translatePrismEvents = function(event) {
	if (event.name === 'status') {
		this._status = event.data
	}

	if (event.data && event.event) {
		var eventsDictionary = {
			'player:load': 'onLoad',
			'player:start': 'onStart',
			'player:resume': 'onResume',
			'player:pause': 'onPause',
			'player:seek': 'onSeek',
			'player:mediaview': 'onMediaView',
			'player:cuepoint': 'onCuepoint',
			'player:progress': 'onProgress',
			'player:listen': 'onListen',
			'player:finish': 'onFinish',
			'player:error': 'onError',
		};
		var status = this._status
		event = {
			title: status.title,
			id: status.id,
			duration: status.duration,
			event: eventsDictionary[event.data.type],
			eventParam: event.data.event
		}
	}
	return event
};

SambaPlayerApiMessage.prototype.setPlayerToReceiveMethod = function(player) {
  this.player = player;
};

SambaPlayerApiMessage.prototype.callMethod = function(method) {
  if (this.player[method.name]) {
    this.player[method.name](method.data);
  }
};

SambaPlayerApiMessage.prototype.doAction = function(method, param, frame){
	var action;
	if (this.isPrism) {
		action = {
			method: true,
			name: method,
			data: [param]
		}
	}
	else {
		action = method;
		if (param != null)
			action += ':' + (typeof param === 'object' ? encodeURIComponent(JSON.stringify(param)) : param);
	}
	this.sendMessage(action, frame);
};

SambaPlayerApiMessage.prototype.getInfo = function(method, fn, frame){
	if (this.isPrism) { // if is prism just call the function with status previously saved
		fn(this._status)
	}
	else {
		this.returnCallback = fn;
		this.sendMessage(method + ":getStatus", frame);
	}
};

SambaPlayerApiMessage.prototype.getQueryParams = function() {
	var result = {};
	var url = SambaPlayerUtils.getAllElementsWithAttribute("samba-player-api")[0].src;
    if (url)
    {
        var params = url.slice(url.indexOf("?") + 1).split("&");
        for (var i = 0; i < params.length; i++)
        {
            var tmp = params[i].split("=");
            result[tmp[0]] = unescape(tmp[1]).replace(/\[|\]/g, "");
        }
    }
		return result;
};

//Tratamento se já existir um script nosso na página
var sambaPlayerApiMessage = sambaPlayerApiMessage || new SambaPlayerApiMessage();

/************************************************************************
	Objeto de controle dos players
*************************************************************************/
var SambaPlayer = function(container, options) {
	EventDispatcher.call(this);
  	sambaPlayerApiMessage = new SambaPlayerApiMessage(this);
	var self = this;
	if(!options.playlist) {
		self.initSingle(container, options);
	}else {
		self.initPlaylist(container, options);
	}
	return this;
};

SambaPlayerUtils.inherit(SambaPlayer, EventDispatcher);

SambaPlayer.prototype.initSingle = function(container, options) {
	var self = this;
	self.MEDIA_ID = options.m || options.live || options.playlistId || "player-" + Math.floor(Math.random()*1000);
	var elem = (typeof container == "string") ? document.getElementById(container) : container;

	//Bindando o iframe correto nos eventos
	sambaPlayerApiMessage.players[self.MEDIA_ID] = options.events || {};

	var elems = SambaPlayerUtils.createAndAppendIframe(elem, self.MEDIA_ID, options, function(iframe, frame){
			self.iframe = iframe;
			self.frame = frame;

			sambaPlayerApiMessage.players[self.MEDIA_ID].event = 'onLoad';
			sambaPlayerApiMessage.players[self.MEDIA_ID].id = self.MEDIA_ID;
			sambaPlayerApiMessage.events(sambaPlayerApiMessage.players[self.MEDIA_ID]);
	});
};
/************************************************************************
	Playlist
*************************************************************************/
SambaPlayer.prototype.initPlaylist = function(container, options) {
  var self = this;
  self.sequence = (options.playlist.sequence) ? options.playlist.sequence : false;
	self.playlist = options.playlist.medias;
	self.currentPlaylist = 0;
	self.loop = options.playlist.loop || false;
	self.timeout = options.playlist.timeout || 5;
	self.autoplay = options.playlist.autoplay || false;

	options.ph = options.playlist.ph;
	options.m = (typeof self.playlist[self.currentPlaylist] === 'string') ?  self.playlist[self.currentPlaylist]: self.playlist[self.currentPlaylist].id;
  	options.events = options.events || {};

	options.events.onLoad = function() {
		if (sambaPlayerApiMessage.isPrism) {
			var config = new Object();
			config.timeout = self.timeout;
			sambaPlayerApiMessage.doAction("setupPlaylistProgress", config, self.frame);
			sambaPlayerApiMessage.setPlayerToReceiveMethod(self);
		}
  }

	//Loop
	options.events.onFinish = function() {
		if(self.sequence) {
      self.currentPlaylist = findMediaPosition(self.playlist, 'id', self.MEDIA_ID)
    }
    self.currentPlaylist = ((self.currentPlaylist + 1) == self.playlist.length) ? 0 : self.currentPlaylist + 1;
		if(self.currentPlaylist != 0 || self.loop) {
			if (sambaPlayerApiMessage.isPrism) {
				sambaPlayerApiMessage.doAction("showProgressSpinner",null, self.frame);
				setTimeout(function() {
					self.sendToNextMedia();
					options.events.onPlaylistClick();
				}, self.timeout * 1000)
			}
			else {
				sambaPlayerApiMessage.doAction("showPlaylist", encodeURIComponent(JSON.stringify({title: self.playlist[self.currentPlaylist].title, timeout: self.timeout})), self.frame);
			}
		}
	};

	options.events.onPlaylistClick = function() {
		var elements = document.getElementsByClassName('samba-playlist-trigger');
		for(var i=elements.length; i--;) {
			if(elements[i].getAttribute('data-mediaid') == self.playlist[self.currentPlaylist] || (self.playlist[self.currentPlaylist].id && elements[i].getAttribute('data-mediaid') === self.playlist[self.currentPlaylist].id) ) {
				elements[i].click();
			}
		}
	};

	options.events.onCancelPlaylist = function() {
		self.currentPlaylist = ((self.currentPlaylist) === 0) ? self.currentPlaylist.length - 1 : self.currentPlaylist - 1;
	};

	this.initSingle(container, options);
	this.bindPlaylist(options.playlist.onClick);
};

SambaPlayer.prototype.sendToNextMedia = function() {
  	var mediaId = this.playlist[this.currentPlaylist].id
  	this.changeVideo(mediaId);
};

SambaPlayer.prototype.changeVideo = function(id, isClick) {
	var self = this;
	var url = self.iframe.src;

	sambaPlayerApiMessage.players[id] = sambaPlayerApiMessage.players[self.MEDIA_ID];

	//Repopulando o currentPlaylist
	for(var i = 0; i<self.playlist.length; i++) {
		if(self.playlist[i] == id) {
			self.currentPlaylist = i;
		}
	}

	if(self.autoplay && !isClick && self.currentPlaylist != 0) {
		url = SambaPlayerUtils.updateQueryStringParameter(self.iframe.src, 'autoStart', true);
	}else {
		url = SambaPlayerUtils.updateQueryStringParameter(self.iframe.src, 'autoStart', false);
	}

	self.iframe.src = url.replace(self.MEDIA_ID, id);
	self.MEDIA_ID = id;
};

SambaPlayer.prototype.bindPlaylist = function(cb) {
	var self = this;
	var triggers = document.querySelectorAll('.samba-playlist-trigger'),
			triggersLength = triggers.length;

	for(var i = triggersLength; i--;) {
		SambaPlayerUtils.addEvent('click', triggers[i], function(evt){
			var target = evt.target || evt.srcElement;
			var mediaId = target.getAttribute('data-mediaid');
			self.changeVideo(mediaId, evt.isTrusted);
			if(typeof cb == "function") {
				cb(mediaId);
			}else if(typeof cb == "string") {
				window[cb](mediaId, evt);
			}
			evt.preventDefault();
		}, false);
	}
};

function findMediaPosition(array, key, value) {
  for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
          return i;
      }
  }
  return null;
}

/************************************************************************
	Fim Playlist
*************************************************************************/

SambaPlayer.prototype.on = function(name, fb){
	sambaPlayerApiMessage.players[this.MEDIA_ID][name] = fb;
};

SambaPlayer.prototype.play = function(){
	sambaPlayerApiMessage.doAction("play", null, this.frame);
};

SambaPlayer.prototype.pause = function(){
	sambaPlayerApiMessage.doAction("pause", null, this.frame);
};

SambaPlayer.prototype.mute = function(){
	sambaPlayerApiMessage.doAction("mute", null, this.frame);
};

SambaPlayer.prototype.unmute = function(){
	sambaPlayerApiMessage.doAction("unmute", null, this.frame);
};

SambaPlayer.prototype.getStatus = function(fn){
	sambaPlayerApiMessage.getInfo("getStatus", function(media){
		fn(media);
	}, this.frame);
};

SambaPlayer.prototype.seek = function(time){
	sambaPlayerApiMessage.doAction("seek", time, this.frame);
};

SambaPlayer.prototype.setDRM = function(drmObj) {
	sambaPlayerApiMessage.doAction("setDRM", drmObj, this.frame);
};

SambaPlayer.prototype.changeSubtitle = function(subtitle) {
	sambaPlayerApiMessage.doAction("changeSubtitle", subtitle, this.frame);
};

SambaPlayer.prototype.voiceScreen = function(data) {
	sambaPlayerApiMessage.doAction("voiceScreen", data, this.frame);
};

SambaPlayer.prototype.setVolume = function(volume) {
	sambaPlayerApiMessage.doAction("setVolume", volume, this.frame);
};

/** Only html5 */
SambaPlayer.prototype.changeSpeed = function(speed) {
	sambaPlayerApiMessage.doAction("changeSpeed", speed, this.frame);
};

//Sync PPT function
SambaPlayer.prototype.syncSlideshow = function(obj) {
	var self = this;
	self.slidePlayer;

 	switch(obj.provider.toLowerCase()) {
		case 'slideshare':
		var params = { allowScriptAccess: "always" };
		var atts = { id: obj.container };
		var flashvars = { id: obj.id, doc : obj.doc, startSlide : 1, rel : 0 };
		try{
			swfobject.embedSWF("http://static.slideshare.net/swf/ssplayer2.swf", obj.container, obj.width, obj.height, "8", null, flashvars, params, atts, function() {
				self.slidePlayer = document.getElementById(obj.container);
			});
		}catch(e) {
			if(console)
				console.info("Swfobject não encontrado!");
		}
	}

	/**Bind the progress function**/
	sambaPlayerApiMessage.players[self.MEDIA_ID]["onListen"] = function(player){
		var time = (player.eventParam) ? Math.floor(player.eventParam) : '';
		if(obj.map[time]) {
			changeSlide(obj.map[time]);
		}
	};

	sambaPlayerApiMessage.players[self.MEDIA_ID]["onFinish"] = function(player){
		changeSlide(1);
	};

	function changeSlide(num) {
		self.slidePlayer.jumpTo(num);
	}
};

//Diminuir as luzes da página
SambaPlayer.prototype.dimLights = function(flag) {
	var self = this;
	if(!self.dimDiv) {
		self.iframe.style.zIndex = 1040;
		self.iframe.style.position = "relative";
		self.dimDiv = document.createElement("div");
		var css = ".sambaplayer-dim-off{display:none;} .sambaplayer-dim-on{opacity: 0.5; width: 100%; height:100%; background-color: #000; bottom: 0; left: 0; position: fixed; right: 0; top: 0; z-index: 1000;}",
		head = document.head || document.getElementsByTagName('head')[0],
		style = document.createElement('style');

		style.type = 'text/css';
		if (style.styleSheet){
			style.styleSheet.cssText = css;
		} else {
			style.appendChild(document.createTextNode(css));
		}
		head.appendChild(style);

		document.getElementsByTagName("body")[0].appendChild(self.dimDiv);
	}

	if(flag) {
		self.dimDiv.className = "sambaplayer-dim-on";
	}else{
		self.dimDiv.className = "sambaplayer-dim-off";
	}
};

SambaPlayer.prototype.validateApiAuthorization = function(receivedMediaId) {
  var currentMediaId = this.MEDIA_ID
  var isAbleToReproduce = currentMediaId === receivedMediaId[0]
  sambaPlayerApiMessage.doAction("setApiValidation", isAbleToReproduce, this.frame);
};

/**
 * Sets annotations to be shown at specific times of the media.
 *
 * @param annotations : Array - Annotations config data
 *	 time : int - Time for showing the annotation (in seconds)
 *   text : String (optional) - Annotation text
 *   url : String (optional) - Link for the text
 *   position : int/Array (optional) - Position of the annotation; defaults to 0
 *     0: top-left
 *	   1: top-right
 *	   2: bottom-left
 *	   3: bottom-right
 *	   4: center
 *	   [x,y]: free (negative values for right/bottom reference)
 *	 delay : int (optional) - Time interval to close (in seconds); defaults to 0 (never close)
 *	 closeable : Boolean (optional) - Show/hide close button; defaults to true
 * @param annotationHandler : Function[annotation : Object, index : int] (optional) - A callback for when user click on each annotation
 * @param noRepeat : Boolean (optional) - Indicates if annotations must repeat or not; defaults to false
 *
 * Example:
 *	[
 *		{
 *			time: 5,
 *			text: "bla bla bla!",
 *			url: "http://www.asdf.com"
 *		},
 *		{
 *			time: 15,
 *			text: "bla bla bla!!!!"
 *		},
 *		{
 *			time: 34,
 *			text: "efs sdoifjsdfo ijawefo iaf!!",
 *			position: [85, -20],
 *			onClick: function(annotation) {
 *				alert("Somebody clicked on me!");
 *			}
 *		},
 *		{
 *			time: 110,
 *			text: "qwri uhasdfoij",
 *			url: "http://www.asdf.com",
 *			position: 1
 *		}
 *	]
 */
SambaPlayer.prototype.addAnnotations = function(annotations, annotationHandler, noRepeat) {
	var self = this;
	var showed = {};
	var timeLast = 0;
	var time;
	var offInterval;

	if (annotationHandler) {
		sambaPlayerApiMessage.players[this.MEDIA_ID]["onAnnotationClick"] = function(e) {
			var index = e.eventParam.index;

			// uninjecting array index in client's config object
			delete e.eventParam.index;

			annotationHandler(e.eventParam, index);
		};
	}

	sambaPlayerApiMessage.players[this.MEDIA_ID]["onListen"] = function(e) {
		time = e.eventParam ? Math.floor(e.eventParam) : '';

		if (timeLast == time)
			return;

		timeLast = time;

		for (var i = annotations.length; i--;) {
			offInterval = time < annotations[i].time ||
				annotations[i].delay && time >= annotations[i].time + annotations[i].delay;

			if (showed[i]) {
				if (offInterval) {
					showed[i] = false;
					self.destroyAnnotations(i);
				}

				continue;
			}
			else if (offInterval)
				continue;

			showed[i] = true;
			// injecting array index in client's config object
			annotations[i].index = i;

			sambaPlayerApiMessage.doAction("showAnnotation", annotations[i], self.frame);
		}
	};

	sambaPlayerApiMessage.players[this.MEDIA_ID]["onFinish"] = function(e) {
		showed = {};
		self.destroyAnnotations();
	};
};
SambaPlayer.prototype.verifyPrism = function(callback,params){
	self = this
	window.addEventListener('message',function (e){
		try {
			var event = typeof e.data === 'string'? JSON.parse(e.data) : e.data
			if (event.data && event.data.type === 'player:prism') {
				callback = callback.bind(self)
				callback(params)
			}
		} catch (error) {
			console.warn(error)
		}
	})
};
SambaPlayer.prototype.initPrismPlugin = function(config){
	function exec() {
		if (config.handler!==null) {
			config.callback(config.params,config.handler);
		} else {
			config.callback(config.params);
		}
	}
	if (this.frame)
		exec();
	else sambaPlayerApiMessage.players[this.MEDIA_ID]["onLoad"] = exec;
};

SambaPlayer.prototype.addMidrollLinks = function(annotations, annotationHandler, noRepeat) {
	var self = this;
	var config = {
		'params' : {
			'apiVersion':'V3',
			'annotations': annotations
		},
		'handler': annotationHandler,
		'callback': self.initAnnotationsPrism.bind(this)
	}
	if (sambaPlayerApiMessage.isPrism) {
		self.initPrismPlugin(config)
	} else {
		self.verifyPrism = self.verifyPrism.bind(this)
		self.verifyPrism(self.initPrismPlugin, config)
		self.addAnnotations(annotations, annotationHandler, noRepeat);
	}
};

SambaPlayer.prototype.initAnnotationsPrism = function(annotations, annotationHandler) {
	this.annotationHandler = annotationHandler;
	sambaPlayerApiMessage.doAction("initMidrollLinks",annotations,this.frame);
	window.addEventListener('message',this.annotationClicked,false);
};

SambaPlayer.prototype.annotationClicked = function (event) {
	try {
		var data = typeof event.data === 'string'? JSON.parse(event.data) : event.data
		if (data.type === 'ANNOTATION-CLICKED' && this.annotationHandler) {
		  var callback = this.annotationHandler;
		  // if it`s string, call function by name, otherwise a function must be passed
		  if (typeof callback === 'string') {
			if (window[callback]) {
			  window[callback](data.annotation, data.index);
			}
		  } else {
			callback(data.annotation, data.index);
		  }
		}
	} catch (error) {
		console.warn(error)
	}
};
/**
 * Destroys all annotations or a single one.
 *
 * @param index (optional) - Index in collection of the annotation to destroy
 */
SambaPlayer.prototype.destroyAnnotations = function(index) {
	sambaPlayerApiMessage.doAction("destroyAnnotations", index, this.frame);
};

SambaPlayer.prototype.destroyMidrollLinks = function(index) {
	this.destroyAnnotations(index);
};

/**
 * Configures comments feature.
 *
 * @param config : Object - Config data
 *	 readOnly : Boolean - Shows comments on cuepoints (HTML5 only) and blocks user interactions; defaults to false
 *	 autoShow : Boolean - Enable/disable automatically showing comments on cuepoints; defaults to false
 *	 timeInterval : int - Group and show comments by n-n seconds
 *	 author : Boolean/String - Whether to show comment author field or to have it pre-filled; defaults to true
 * @param comments : Array - "addComments()"
 */

SambaPlayer.prototype.initComments = function (config, comments) {
	self = this;
	var params = {
		'params':{
			'apiVersion':'V3',
			'commentsConfig': {
				'config': config,
				'comments': comments
			}
		},
		'handler': null,
		'callback': self.initCommentsPrism.bind(this)
	}
	if (sambaPlayerApiMessage.isPrism) {
		self.initPrismPlugin(params)
	} else {
		self.verifyPrism = self.verifyPrism.bind(this)
		self.verifyPrism(self.initPrismPlugin,params)
		self.initCommentsQuartz(config,comments)
	}
}
SambaPlayer.prototype.initCommentsPrism= function (parameters) {
	sambaPlayerApiMessage.doAction("initComments",parameters,this.frame);
}
SambaPlayer.prototype.initCommentsQuartz = function(config, comments) {
	var self = this;
	var timeInterval = config.timeInterval > 0 ? config.timeInterval : 5;
	var lastTime = -1;
	if (comments)
		comments = this.treatCommentData(comments);

	function exec() {
		sambaPlayerApiMessage.doAction("initComments", config, self.frame);
		comments && self.addComments(comments);
	}
	if (this.frame)
		exec();
	else sambaPlayerApiMessage.players[this.MEDIA_ID]["onStart"] = exec;

	// user action
	sambaPlayerApiMessage.players[this.MEDIA_ID]["onCommentAdded"] = function(e) {
		self.dispatchEvent({type: "commentAdded", data: e.eventParam});

		if(comments.cm[e.eventParam.time] == null) {
			comments.cm[e.eventParam.time] = [];
			comments.points.push(e.eventParam.time);
		}

		comments.cm[e.eventParam.time].push(e.eventParam);
	};

	//On comment show
	sambaPlayerApiMessage.players[this.MEDIA_ID]["onCommentCuepoint"] = function(e) {
		var time = +e.eventParam.time;
		if (e.eventParam.dontGroup)
			sambaPlayerApiMessage.doAction("showComment", comments.cm[time], self.frame);
		else {
			var cms = [];
			var n = timeInterval;

			if (lastTime === -1 || time - lastTime > timeInterval) {
				lastTime = time;

				while (n--) {
					if (!comments.cm.hasOwnProperty(time + n))
						continue;

					cms = cms.concat(comments.cm[time + n]);
				}

				sambaPlayerApiMessage.doAction("showComment", cms, self.frame);
			}
		}
	};

	sambaPlayerApiMessage.players[this.MEDIA_ID]["onPause"] =
	sambaPlayerApiMessage.players[this.MEDIA_ID]["onSeek"] =
	sambaPlayerApiMessage.players[this.MEDIA_ID]["onFinish"] = function(e) {
		lastTime = -1;
	};
};

/**
 * Adds comments to be shown at specific media times.
 *
 * @param comments : Array - Comments config data
 *	 time : int - Time for showing the comment (in seconds)
 *   text : String - Comment text
 *	 author : String - Comment author name
 */
SambaPlayer.prototype.addComments = function(comments) {
	if (comments.points == null)
		comments = this.treatCommentData(comments);

	sambaPlayerApiMessage.doAction("addComments", comments.points, this.frame);
};

SambaPlayer.prototype.treatCommentData = function(comments) {
	var cuepoints = [];
	var commentsParsed = {};

	for(var key in comments) {
		if(comments.hasOwnProperty(key)) {
			var time = comments[key].time;
			cuepoints.push(time);
			if(commentsParsed.hasOwnProperty(time)) {
				commentsParsed[time].push(comments[key]);
			}else {
				commentsParsed[time] = [comments[key]];
			}

		}
	}

	return {points: cuepoints, cm: commentsParsed};
};
